<template>
  <div id="ezuikitMain" class="hello-ezuikit-js">
    <div
      v-if="noErr"
      class="monitor_area"
      :style="[
        mainWidth && mainHeight
          ? `width:${mainWidth}px;height:${mainHeight}px`
          : ``,
      ]"
    >
      <div id="video-container"></div>
    </div>

    <div v-else class="err_text">路径错误，请返回重试！</div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
var player = null;

export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      noErr: true,
      mainWidth: 0,
      mainHeight: 0,
    };
  },
  beforeCreate() {
    document.title = "圣联智控";
  },
  mounted() {
    this.getParams();
    setTimeout(() => {
      this.init();
    }, 500);
  },
  destroyed() {
    if (player) {
      this.destroy();
    }
  },
  methods: {
    getParams() {
      document.title = this.getUrlParam("name") || "监控画面";
    },
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return decodeURIComponent(r[2]);
      return null; //返回参数值
    },
    init() {
      if (player) {
        this.destroy();
      }
      const url = this.getUrlParam("url");
      const token = this.getUrlParam("token");

      if (!url && !token) {
        this.noErr = false;
        return;
      }

      const widths = document.getElementById("ezuikitMain").offsetWidth;
      const heights = (widths / 16) * 9;

      player = new EZUIKit.EZUIKitPlayer({
        id: "video-container",
        accessToken: token,
        url,
        useHardDev: true,
        dpr: 100,
        // simple: 极简版; pcLive: pc直播; pcRec: pc回放; mobileLive: 移动端直播; mobileRec: 移动端回放;security: 安防版; voice: 语音版;
        template: "pcLive",
        plugin: ["talk"],
        width: widths,
        height: heights,
      });
      window.player = player;
    },
    destroy() {
      var destroyPromise = player.destroy();
      destroyPromise.then((data) => {
        console.log("promise 获取 数据", data);
      });
      player = null;
    },
  },
};
</script>

<style scoped>
.hello-ezuikit-js {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* .monitor_area{} */

.err_text {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 80px;
  color: black;
}
</style>
